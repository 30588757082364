import React, { useEffect, useState } from 'react';
import styles from './invoice-generator.module.scss';
import plus from '../../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../../Assets/Images/Icons/Other/minus.png';
import { createInvoice, getClients } from '../../../../Services/api.service';
import { useNavigate } from 'react-router-dom';
import {
    addColumn,
    addRow,
    convertTableToData,
    deleteLastColumn,
    deleteLastRow,
    excelToList,
    QuoteOptions,
} from '../../QuoteGenerator/QuoteGenerator';
import DatePicker, { dateToUnixTimeStamp } from '../../../../Components/DatePicker/DatePicker';
import Loader from '../../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const InvoiceGenerator = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(0);
    const [price, setPrice] = useState('');
    const [tax, setTax] = useState('');
    const [total, setTotal] = useState('');
    const [dateTs, setDateTs] = useState(dateToUnixTimeStamp(new Date()));
    const [pastedExcel, setPastedExcel] = useState('');
    const [invoiceType, setInvoiceType] = useState('Janitorial');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setClients(await getClients());
            setLoading(false);
        })();
    }, []);

    const submitButtonHandler = () => {
        let dataSource = [];

        if (pastedExcel.length > 0) {
            dataSource = pastedExcel;
        } else {
            dataSource = convertTableToData();
        }

        const data = {
            clientId: client,
            subTotal: price,
            tax: tax,
            total: total,
            dateTs: dateTs,
            lineData: sourceToJson(dataSource),
            type: invoiceType,
        };

        if (invoiceGenerateValidator(data)) {
            const confirmBox = window.confirm('Are you sure ?');

            if (confirmBox === true) {
                setLoading(true);
                createInvoice(data).then((response) => {
                    if (response.status === 201) {
                        navigate(-1);
                    }
                });
            }
        }
    };

    const priceHandle = (price) => {
        const taxFormatted = parseFloat(price * 0.13).toFixed(2);
        const totalFormatted = parseFloat(price * 1.13).toFixed(2);

        setPrice(price);
        setTax(taxFormatted);
        setTotal(totalFormatted);
    };

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <h2 className='center-align'>Invoice Generator</h2>

                    <div className={styles.container}>
                        <h4>Invoice Type</h4>
                        <select
                            value={invoiceType}
                            onChange={(e) => setInvoiceType(e.target.value)}
                        >
                            <QuoteOptions />
                        </select>

                        <h4>Company / Address</h4>
                        <select value={client} onChange={(e) => setClient(e.target.value)}>
                            <option value={0} disabled='disabled'>
                                Select Client
                            </option>
                            {clients
                                .filter((client) => client.isActive)
                                .map((client) => (
                                    <option key={client.id} value={client.id}>
                                        {client.companyName && client.fullName
                                            ? client.companyName + ' - ' + client.fullName
                                            : client.fullName}
                                    </option>
                                ))}
                        </select>

                        <h4>Line Items</h4>
                        <div className={styles.tableController}>
                            <div className={styles.tableControllerButtons}>
                                <img src={minus} onClick={deleteLastColumn} alt='minus' />
                                <h4>Column</h4>
                                <img src={plus} onClick={addColumn} alt='plus' />
                            </div>

                            <div className={styles.tableControllerButtons}>
                                <img src={minus} onClick={deleteLastRow} alt='minus' />
                                <h4>Row</h4>
                                <img src={plus} onClick={addRow} alt='plus' />
                            </div>
                        </div>

                        <div className={styles.quoteLineItems}>
                            <table id='myTable'>
                                <thead>
                                    <tr id='headerRow'>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id='tableBody'>
                                    <tr id='row'>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                    </tr>
                                    <tr id='row'>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h4>Paste from excel</h4>
                        <textarea
                            type='text'
                            value={pastedExcel}
                            onChange={(e) => setPastedExcel(excelToList(e.target.value))}
                            placeholder='Paste from excel only'
                        />

                        <div className={styles.priceEmailWrapper}>
                            <h4>Date</h4>
                            <DatePicker
                                initialTimestamp={dateTs}
                                onChange={(unixTimeStamp) => setDateTs(unixTimeStamp)}
                            />
                            <h4>Subtotal</h4>
                            <input
                                type='text'
                                value={price}
                                onChange={(e) => priceHandle(e.target.value)}
                                placeholder='Total Price (required)'
                            />
                            <h4>Tax</h4>
                            <input type='text' value={tax} disabled />
                            <h4>Total</h4>
                            <input type='text' value={total} disabled />
                        </div>

                        <button onClick={submitButtonHandler}>Create</button>
                    </div>
                </>
            )}
        </>
    );
};

export default InvoiceGenerator;

export const sourceToJson = (rows) => {
    let result = {
        columns: rows[0],
        data: [],
    };
    rows.shift();
    rows.forEach((row) => {
        let lineObj = {};
        for (let i = 0; i < row.length; i++) {
            lineObj[result.columns[i]] = row[i];
        }
        result.data.push(lineObj);
    });

    return result;
};

export const invoiceGenerateValidator = (state) => {
    if (state.client === 0 || state.price === '' || state.date === '' || state.invoiceType === 2) {
        window.alert('Check fields');
        return false;
    }
    return true;
};
