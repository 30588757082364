import styles from './checkbox.module.scss';

const Checkbox = (props) => {
    return (
        <div className={styles.container}>
            <p>
                <b>{props.label}</b>
            </p>
            <input type='checkbox' checked={props.checked} onChange={props.onChange} />
        </div>
    );
};

export default Checkbox;
