import styles from './textarea.module.scss';

const TextArea = (props) => {
    return (
        <div className={styles.container}>
            <p>
                <b>{props.label}</b>
            </p>

            <textarea value={props.value} onChange={props.onChange} disabled={props.disabled} />
        </div>
    );
};

export default TextArea;
