import React, { useState } from 'react';
import styles from './stories.module.scss';
import { useNavigate } from 'react-router-dom';

const Stories = (props) => {
    const navigate = useNavigate();
    const [stories] = useState(props.data);

    let StoryCards = stories.map((story, index) => {
        return (
            <div
                className={styles.storyCard}
                key={story.id}
                onClick={() => {
                    // send index instead of id cuz, gallery runs based on indexes not ids
                    navigate(`/gallery/${index + 1}`, { state: props.data });
                }}
            >
                <div className={styles.coverLogo}>
                    <img
                        className={styles.box}
                        src={require(
                            `../../Assets/Images/Logos/ClearServices Logos/${story.company}.png`,
                        )}
                        alt={story.type + story.coverImg}
                    />
                </div>
                <img
                    loading='lazy'
                    src={require(`../../Assets/Gifs/${story.type}/${story.coverImg}`)}
                    alt={story.coverImg}
                />
            </div>
        );
    });

    return (
        <>
            <div className={styles.storyContainer}>{StoryCards}</div>
        </>
    );
};

export default Stories;
