import { useEffect, useState } from 'react';
import { PaintQuoteState } from '../models';
import { paintQuote } from '../../../../Services/api.service';
import plus from '../../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../../Assets/Images/Icons/Other/minus.png';
import styles from '../CommercialQuote/commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import Button from '../../../../Components/Button/Button';

const PaintQuote = () => {
    const [paint, setPaint] = useState(PaintQuoteState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPaint(PaintQuoteState);
    }, []);

    const submitPaintQuote = async () => {
        if (paintQuoteValidator(paint)) {
            setLoading(true);
            setSubmitted(true);
            await paintQuote(paint)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={`${styles.componentWrapper} ${styles.contractingComponentWrapper}`}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Rooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (paint.room > 0) {
                                    setPaint({ ...paint, room: paint.room - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={paint.room} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (paint.room < 20) {
                                    setPaint({ ...paint, room: paint.room + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Bathrooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (paint.bathroom > 0) {
                                    setPaint({ ...paint, bathroom: paint.bathroom - 0.5 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={paint.bathroom} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (paint.bathroom < 8) {
                                    setPaint({ ...paint, bathroom: paint.bathroom + 0.5 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Hallways</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (paint.hallway > 0) {
                                    setPaint({ ...paint, hallway: paint.hallway - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={paint.hallway} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (paint.hallway < 5) {
                                    setPaint({ ...paint, hallway: paint.hallway + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Staircases</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (paint.staircase > 0) {
                                    setPaint({ ...paint, staircase: paint.staircase - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={paint.staircase} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (paint.staircase < 4) {
                                    setPaint({ ...paint, staircase: paint.staircase + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Windows</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (paint.window > 0) {
                                    setPaint({ ...paint, window: paint.window - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={paint.window} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (paint.window < 50) {
                                    setPaint({ ...paint, window: paint.window + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Doors</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (paint.door > 0) {
                                    setPaint({ ...paint, door: paint.door - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={paint.door} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (paint.door < 50) {
                                    setPaint({ ...paint, door: paint.door + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Other</h3>
                    <div className={styles.dayWrapper}>
                        <div
                            className={styles.dayRows}
                            onClick={() => setPaint({ ...paint, living: !paint.living })}
                        >
                            <h5>Living Room</h5>
                            <input type='checkbox' checked={paint.living} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() => setPaint({ ...paint, dining: !paint.dining })}
                        >
                            <h5>Dining Room</h5>
                            <input type='checkbox' checked={paint.dining} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() => setPaint({ ...paint, kitchen: !paint.kitchen })}
                        >
                            <h5>Kitchen</h5>
                            <input type='checkbox' checked={paint.kitchen} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() => setPaint({ ...paint, basement: !paint.basement })}
                        >
                            <h5>Rec Room</h5>
                            <input type='checkbox' checked={paint.basement} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() => setPaint({ ...paint, baseboard: !paint.baseboard })}
                        >
                            <h5>Baseboards</h5>
                            <input type='checkbox' checked={paint.baseboard} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() => setPaint({ ...paint, ceiling: !paint.ceiling })}
                        >
                            <h5>Ceiling</h5>
                            <input type='checkbox' checked={paint.ceiling} readOnly />
                        </div>
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setPaint({ ...paint, email: e.target.value.toLowerCase() })
                        }
                        value={paint.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.contractingButton}>
                        <Button
                            onClick={!submitted ? () => submitPaintQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaintQuote;

const paintQuoteValidator = (paint) => {
    if (
        paint.room === 0 &&
        paint.bathroom === 0 &&
        !paint.kitchen &&
        !paint.basement &&
        !paint.living &&
        !paint.dining
    ) {
        alert('Must meet minimum service requirements.');
        return false;
    }

    return !!emailValidator(paint.email);
};
