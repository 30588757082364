import HeaderComponent from '../../../Components/Header/Header';
import FooterComponent from '../../../Components/Footer/Footer';
import styles from './privacy.module.scss';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>ClearServices - Privacy Policy</title>
                <meta name='description' content='Clear Services Group privacy policy' />
                <link rel='canonical' href='/privacy-policy' />
            </Helmet>
            <HeaderComponent />

            <div className={styles.textContainer}>
                <h2 className={styles.centerAlign}>Privacy Policy</h2>

                <h4>EFFECTIVE DATE</h4>
                <p>As of January 1, 2022, Clear Services Group Inc. privacy policy have been adjusted.</p>

                <h4>INTRODUCTION</h4>
                <p>
                    Clear Services Group Inc. as amended from time to time (collectively, "we," "us," or
                    "our") value your privacy. In all of our interactions with you, we are committed
                    to retaining your confidence and trust.
                </p>
                <p>
                    The following is our Privacy Policy, which governs how we collect, use,
                    disclose, and retain personal information about you when you visit our websites
                    (the "Site" or "Sites"), book a service with us, or otherwise contact with us.
                </p>

                <h4>IDENTIFYING PURPOSES</h4>
                <p>
                    We only collect, use, disclose, and retain personal information for the reasons
                    for which it was collected or as necessary or authorised by law. Unless you give
                    us permission, we will not collect, use, disclose, or retain personal
                    information for any other purpose.
                </p>
                <p>Personal information about you may be collected for the following reasons:</p>

                <ul>
                    <li>to provide the service(s) that you have requested;</li>
                    <li>
                        to provide you with new products and services that we believe you might
                        enjoy;
                    </li>
                    <li>to conduct satisfaction surveys with customers;</li>
                    <li>in order to answer to your inquiries;</li>
                    <li>
                        to keep you informed about new deals, products, and services that we offer;
                    </li>
                    <li>to raise the quality of our service;</li>
                    <li>to send out service announcements</li>
                    <li>Your personal information may be shared with our personnel.</li>
                </ul>

                <p>
                    Only as much information as is required to offer services to you or for the
                    purposes indicated in this Privacy Policy will be shared.
                </p>
                <p>Your personal information may also be shared with the following entities:</p>

                <ul>
                    <li>our linked businesses;</li>
                    <li>
                        unaffiliated service providers, such as website hosting companies,
                        fulfilment companies, payment processing companies, and other third-party
                        service providers, with whom we may subcontract; and
                    </li>
                    <li>
                        when obliged to react to subpoenas, court orders, or legal process, or to
                        establish or exercise our legal rights, or to defend against legal claims,
                        government agencies and law enforcement officials.
                    </li>
                </ul>

                <h4>CONSENT</h4>
                <p>
                    To provide you with products or services, we require your permission. We will
                    seek your permission before collecting, using, disclosing, or retaining your
                    personal information. This Privacy Policy outlines the exceptions to when we
                    will ask for consent, such as when we are authorised or required by law. Consent
                    can be granted verbally, in writing, or through an authorised representative
                    such as a lawyer, agent, or broker. Oral, written, or electronic consent are all
                    acceptable forms of express consent. When we can reasonably infer consent from
                    your circumstances, we call it implied consent.
                </p>
                <p>Withdrawal of Consent</p>
                <p>
                    You have the right to withdraw or decline your permission to the collection,
                    use, disclosure, or retention of your personal information. We may be unable to
                    provide you with a product or service as a result of your withdrawal or refusal.
                </p>
                <p>
                    You can opt out of receiving marketing or other communications from us at any
                    time. Please email us at contact@clearservices.ca or phone us at +1 226-600-9243
                    to do so.
                </p>

                <h4>LIMITING COLLECTION, USE, DISCLOSURE AND RETENTION</h4>
                <p>
                    We make every effort to acquire just as much personal information as is
                    necessary and appropriate for the purposes identified. Except with your approval
                    or as permitted or required by applicable law, we will not use or disclose your
                    personal information for purposes other than those for which it was acquired.
                    Personal information will be kept for no longer than is necessary to accomplish
                    the purpose for which it was acquired, or as required by law.
                </p>

                <h4>ACCURACY AND SAFEGUARDING</h4>
                <p>
                    We are dedicated to maintaining the accuracy, completeness, and security of your
                    personal information. Security safeguards relevant to the sensitivity level of
                    the information will be used to secure personal information. We take all
                    reasonable steps to safeguard your Personal Information against loss or theft,
                    unauthorised access or disclosure, copying, or modification.
                </p>

                <h4>ACCESS TO PERSONAL INFORMATION</h4>
                <p>
                    You have the right to see your personal information that we have on file. You
                    also have the right to question the information's accuracy and completeness, and
                    to have it changed as necessary.
                </p>

                <h4>CHALLENGING COMPLIANCE</h4>
                <p>
                    You have the right to question our company's adherence to this Privacy Policy.
                    If you have any questions or concerns about our compliance with this Privacy
                    Policy, please contact us using the information provided in this Privacy Policy.
                </p>

                <h4>BUSINESS TRANSFER</h4>
                <p>
                    We may sell or purchase the assets of similar firms as we grow. Customer and
                    user information is typically one of the transferred corporate assets in such
                    agreements. Furthermore, if we sell all or substantially all of our assets, such
                    information may be one of the transferred assets, and we reserve the right to
                    buy or sell such assets.
                </p>

                <h4>ADDITIONAL RIGHTS</h4>
                <h4>CATEGORIES OF PERSONAL INFORMATION COLLECTED</h4>
                <p>
                    The following categories of personal information that we collect or have
                    obtained from consumers in the last 12 months:
                </p>

                <ul>
                    <li>
                        Name, address (including email address), and phone number are examples of
                        identifiers.
                    </li>
                    <li>
                        Customer information such as billing and shipping addresses, as well as
                        credit card numbers;
                    </li>
                    <li>Information about commercial transactions, such as services acquired;</li>
                </ul>

                <h4>RIGHT TO KNOW AND DELETE</h4>
                <p>
                    Consumers have the right to have personal information obtained from them
                    deleted. We usually make every effort to erase your personal information after
                    receiving a request. However, under certain circumstances, such as for our
                    lawful internal business reasons or to comply with a legal requirement, we may
                    be obligated to retain your personal information in accordance with the
                    permissible exceptions described in relevant law. We will notify you if we are
                    unable to comply with your deletion request. You have the right to know specific
                    details about our data practises over the previous 12 months.
                </p>

                <h4>RIGHT TO OPT-OUT</h4>
                <p>
                    We don't often sell information in the classic sense of the word. However, if we
                    sell your personal information as defined by relevant legislation, you have the
                    right to opt-out at any time. By writing us at contact@clearservices.ca, you can
                    request to be removed from our mailing list.
                </p>

                <h4>AUTHORIZED AGENT</h4>
                <p>
                    You have the option of appointing a representative to submit requests on your
                    behalf. We will need formal documentation of the agent's authorization to
                    exercise this right, as well as direct verification of your identification.
                </p>

                <h4>RIGHT TO NON-DISCRIMINATION</h4>
                <p>
                    We shall not treat you unfairly if you exercise any of the rights outlined in
                    this Privacy Policy.
                </p>

                <h4>SITE SPECIFIC TERMS OF USE</h4>
                <p>
                    You express your consent to the collection, use, disclosure, and retention of
                    your personal information in accordance with this Privacy Policy by using this
                    Site or submitting personal information to us through or in connection with this
                    Site. You may not use this Site if you do not agree to the collection, use,
                    disclosure, and keeping of your personal information in accordance with this
                    Privacy Policy.
                </p>

                <h4>CHILDREN’S PRIVACY PROTECTION</h4>
                <p>
                    The Site is not intended for children, and no items or services are available
                    for them to use. We do not intentionally collect information from children under
                    the age of eighteen. If you are under the age of 18, you may only use the Site
                    under the supervision of a parent or guardian, and you may not use any portion
                    of the Site that requires you to provide personal information.
                </p>

                <h4>LINKED INTERNET WEBSITES</h4>
                <p>
                    The Site may contain hypertext links to other websites with privacy policies
                    that differ from those set forth herein. We have no control over, and are not
                    responsible for, any information collected through those websites, and we
                    expressly disclaim any and all liability associated with such collection, use,
                    disclosure, or retention. You should read the privacy policies of any other
                    websites you visit after clicking on a link from the Site.
                </p>

                <h4>SECURITY</h4>
                <p>
                    To keep your online session secure, we employ a number of industry-standard
                    safeguards. Although the Site has security measures in place to protect against
                    the loss, misuse and alteration of information under our control, we cannot
                    guarantee that such security measures will be sufficient, will not be breached,
                    and that your information may not be disclosed or otherwise accessed in any
                    unauthorized manner. You may receive certain information by electronic mail.
                    Despite the fact that intercepting or disclosing these messages may be criminal,
                    these transmissions are not secure.
                </p>

                <h4>MODIFICATION</h4>
                <p>
                    We may make changes to this Site as we grow and add new features, or as legal
                    requirements change.
                </p>
                <p>
                    Note: this Privacy Policy has been prepared to ensure compliance with Canadian
                    privacy law.
                </p>
            </div>
            <FooterComponent />
        </>
    );
};

export default PrivacyPolicy;
