import axios from 'axios';

const register = (username, email, password) => {
    return axios.post(process.env.REACT_APP_API_URL + `rest-auth/registration/`, {
        username,
        email,
        password,
    });
};
const login = async (username, password) => {
    axios.defaults.headers = {
        'Content-Type': 'application/json',
    };
    return await axios
        .post(process.env.REACT_APP_API_URL + `rest-auth/login/`, {
            username,
            password,
        })
        .then((response) => {
            if (response.data) {
                const role = response.data.role;
                
                if (role === 'Admin') {
                    localStorage.setItem('roleLevel', 2);
                }
                else {
                    localStorage.setItem('roleLevel', 1);
                }

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', role);
                localStorage.setItem('username', response.data.username);
                localStorage.setItem('syncTs', new Date());
                axios.defaults.headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${response.data.token}`,
                };
            }
            return response.data;
        });
};
const isLogged = () => {
    if (localStorage.getItem('token')) {
        const userRole = localStorage.getItem('role');
        const response = {"auth": true, "role": userRole};
        return response;
    }
    return false;
};
const logout = () => {
    localStorage.removeItem('user');
};
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};
const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    isLogged,
};
export default AuthService;
