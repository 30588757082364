import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { todaysService } from '../../../Services/api.service';
import styles from './todaysSchedule.module.scss';
import Header from '../../../Components/Header/Header';
import { unixTimestampToDateTime } from '../../../Components/DatePicker/DatePicker';
import { Helmet } from 'react-helmet-async';
import Button from '../../../Components/Button/Button';

const TodaysSchedule = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [secret] = useState(params.secret);
    const [services, setServices] = useState([]);
    const [copyList, setCopyList] = useState([]);
    const [displayContract, setDisplayContract] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (!secret) {
                navigate(-1);
            } else {
                const data = await todaysService(secret, true);
                setServices(data);
                setLoading(false);
            }
        })();
    }, [secret, navigate]);

    useEffect(() => {
        if (services.length > 0) {
            setCopyList(services.filter((x) => x.isContract === displayContract));
        }
    }, [displayContract, services]);

    let dataRender;
    if (copyList.length > 0) {
        dataRender = copyList.map((data) => {
            return (
                <div className={styles.serviceContainer} key={data.serviceCode}>
                    <div className={styles.serviceContainerInner}>
                        <p>{data.companyName ? data.companyName : data.clientName}</p>
                        <p>{data.phoneNumber ? data.phoneNumber : '---'}</p>
                        <p>{unixTimestampToDateTime(data.dateTs)}</p>
                        <a
                            href={`https://maps.google.com/?q=` + data.address + ', ' + data.city}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Address : {data.address + ', ' + data.city}
                        </a>
                    </div>
                    <textarea disabled value={data.instructions} />
                </div>
            );
        });
    }

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            {loading ? (
                <div className='loader-admin' />
            ) : (
                <>
                    <Header />
                    <div className={styles.buttonContainer}>
                        <Button
                            buttonText='Contract'
                            onClick={() => {
                                setDisplayContract(true);
                            }}
                        />
                        <Button
                            buttonText='Single'
                            onClick={() => {
                                setDisplayContract(false);
                            }}
                        />
                    </div>
                    <div className={styles.scheduleContainer}>{dataRender}</div>
                </>
            )}
        </>
    );
};

export default TodaysSchedule;
