import { dateToUnixTimeStamp } from '../../../Components/DatePicker/DatePicker';

export const accordionState = {
    commercial: false,
    residential: false,
    carpet: false,
    paint: false,
};

export const commercialState = {
    frequency: 'none',
    days: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    },
    hours: 1,
    email: '',
};

export const ResidentialState = {
    type: '2',
    timestamp: dateToUnixTimeStamp(new Date()),
    room: 0,
    bathroom: 0,
    pet: false,
    kitchen: false,
    basement: false,
    garage: false,
    dining: false,
    living: false,
    email: '',
};

export const CarpetState = {
    timestamp: dateToUnixTimeStamp(new Date()),
    room: 0,
    stairCase: 0,
    basement: false,
    email: '',
};

export const StudentQuoteState = {
    timestamp: dateToUnixTimeStamp(new Date()),
    building: 'none',
    unit: 0,
    fullUnit: false,
    commonArea: false,
    rooms: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    },
    email: '',
};

export const GroutQuoteState = {
    room: 0,
    bathroom: 0,
    hallway: 0,
    kitchen: 0,
    shower: 0,
    email: '',
};

export const PaintQuoteState = {
    room: 0,
    bathroom: 0,
    window: 0,
    door: 0,
    hallway: 0,
    staircase: 0,
    living: false,
    dining: false,
    kitchen: false,
    basement: false,
    baseboard: false,
    ceiling: false,
    email: '',
};

export const FloorQuoteState = {
    removeFloorType: 'N/A',
    installFloorType: 'N/A',
    totalArea: '',
    email: '',
};

export const MailState = {
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
};
