export const setItem = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const getItem = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const getEntity = (key, id) => {
    return getItem(key).find((element) => element.id === parseInt(id));
};
