import { useEffect, useState } from 'react';
import styles from './header.module.scss';

import ClearCleaning from '../../Assets/Images/Logos/ClearServices Logos/ClearCleaning.png';
import ClearServices from '../../Assets/Images/Logos/ClearServices Logos/ClearServices.png';
import ClearContracting from '../../Assets/Images/Logos/ClearServices Logos/ClearContracting.png';

const Header = () => {
    const [displayBoth, setDisplayBoth] = useState(true);
    const [displayJanitorial, setDisplayJanitorial] = useState(false);
    const currentURL = window.location.href.split('/')[4];

    useEffect(() => {
        if (currentURL === 'Janitorial') {
            setDisplayBoth(false);
            setDisplayJanitorial(true);
        }

        if (currentURL === 'Contracting') {
            setDisplayBoth(false);
            setDisplayJanitorial(false);
        }
    }, [currentURL]);

    return (
        <>
            <div className={styles.container}>
                <a href='/'>
                    {displayBoth ? (
                        <>
                            <img src={ClearCleaning} alt='ClearCleaning' />
                            <img src={ClearServices} alt='ClearServices' />
                            <img src={ClearContracting} alt='ClearContracting' />
                        </>
                    ) : (
                        <>
                            <img src={ClearServices} alt='ClearServices' />
                            {displayJanitorial ? (
                                <img src={ClearCleaning} alt='ClearCleaning' />
                            ) : (
                                <img src={ClearContracting} alt='ClearContracting' />
                            )}
                        </>
                    )}
                </a>
            </div>
        </>
    );
};

export default Header;
