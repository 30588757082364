import styles from './studentSpecial.module.scss';
import clearserviceslogo from '../../../Assets/Images/Logos/ClearServices Logos/ClearServices.png';
import clearcleaninglogo from '../../../Assets/Images/Logos/ClearServices Logos/ClearCleaning.png';
import Footer from '../../../Components/Footer/Footer';
import StudentQuote from '../Quote/StudentHouseQuote/StudentQuote';
import kst1 from '../../../Assets/Images/Projects/King Street 1.jpeg';
import kst from '../../../Assets/Images/Projects/King Street 2.jpg';
import bh from '../../../Assets/Images/Projects/Bridgeport.png';
import ph from '../../../Assets/Images/Projects/Preston.png';
import rez from '../../../Assets/Images/Projects/MyRez.jpeg';
import prices from '../../../Data/PriceList.json';

const StudentSpecial = () => {
    const dataRender = Object.keys(prices).map((data) => {
        return (
            <>
                <h2>{data}</h2>
                <div className={styles.container} key={data}>
                    {prices[data].map((item) => (
                        <div className={styles.discountBox} key={item.someUniqueKey}>
                            <h3>{item.numberOfService}</h3>
                            <div className={styles.discountBoxInner}>
                                <div className={styles.discountLine}></div>
                                <h4>{item.price}</h4>
                                <h4>{item.discountPrice}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    });

    return (
        <>
            <div className={styles.header}>
                <img src={clearserviceslogo} alt='logo' />
                <img src={clearcleaninglogo} alt='logo' />
            </div>

            <div className={styles.priceList}>
                <h2>Student Special</h2>
                <p>
                    Our student house cleaning service includes a comprehensive range of tasks to
                    keep your living space clean and comfortable. Our services include:
                    <br />
                    <br />
                    Vacuuming and mopping all types of flooring to remove dirt and dust.
                    <br />
                    <br />
                    Cleaning of baseboards, doors, lights, closets, sinks, toilets, mirrors,
                    bathtubs, vent fans, window sills, and countertops to ensure that all surfaces
                    are spotless.
                    <br />
                    <br />
                    Thorough cleaning of appliances, cabinets, drawers, and windows from both the
                    inside and outside to remove any dirt, grime or smudges.
                    <br />
                    <br />
                    Explore our available deals and schedule them as you like throughout the year !
                </p>
                {dataRender}
            </div>

            <div className={styles.middleContainer}>
                <h4>
                    Get a quote now! You'll receive a prompt quote in your email, simply respond to
                    the email to experience a tidy environment.
                </h4>
            </div>

            <StudentQuote />

            <div className={styles.showCase}>
                <div>
                    <img src={kst1} alt='building' />
                    <h3>King Street Tower 1</h3>
                </div>
                <div>
                    <img src={kst} alt='building' />
                    <h3>King Street Tower 2</h3>
                </div>
                <div>
                    <img src={rez} alt='building' />
                    <h3>MyRez</h3>
                </div>
                <div>
                    <img src={bh} alt='building' />
                    <h3>Bridgeport House</h3>
                </div>
                <div>
                    <img src={ph} alt='building' />
                    <h3>Preston House</h3>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default StudentSpecial;
