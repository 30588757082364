import { useNavigate } from 'react-router-dom';
import styles from './serviceCard.module.scss';

import Checkmark from '../../Assets/Images/Icons/Other/blue-check.png';

const ServiceCard = (props) => {
    const navigate = useNavigate();

    let isContracting;

    if (props.type === 'Contracting') {
        isContracting = styles.contractingBorder;
    }

    return (
        <div
            className={props.className ? props.className : `${styles.container} ${isContracting}`}
            onClick={props.type ? () => navigate(`/services/${props.type}/${props.serviceName}`) :  null}
        >
            {props.quoteAvailable ? (
                <div className={styles.quoteCheck}>
                    <p>Instant quote</p>
                    <img src={Checkmark} alt='Checkmark' />
                </div>
            ) : (
                <p></p>
            )}
            <img
                src={require(`../../Assets/Images/Icons/Service/${props.icon}`)}
                alt={props.serviceName}
            />
            <p>{props.serviceName}</p>
        </div>
    );
};

export default ServiceCard;
