import { useEffect, useState } from 'react';
import DatePicker, { dateToUnixTimeStamp } from '../../../../Components/DatePicker/DatePicker';
import { ResidentialState } from '../models';
import { residentialQuote } from '../../../../Services/api.service';
import plus from '../../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../../Assets/Images/Icons/Other/minus.png';
import styles from '../CommercialQuote/commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import Button from '../../../../Components/Button/Button';

const ResidentialQuote = () => {
    const [residential, setResidential] = useState(ResidentialState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setResidential(ResidentialState);
    }, []);

    const submitResidentialQuote = async () => {
        if (residentialQuoteValidator(residential)) {
            setLoading(true);
            setSubmitted(true);
            await residentialQuote(residential)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={styles.componentWrapper}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Service Type</h3>
                    <select
                        value={residential.type}
                        onChange={(e) => setResidential({ ...residential, type: e.target.value })}
                    >
                        <ServiceOptions />
                    </select>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Date</h3>
                    <DatePicker
                        className={styles.datePicker}
                        initialTimestamp={dateToUnixTimeStamp(new Date())}
                        onChange={(unixTimeStamp) =>
                            setResidential({
                                ...residential,
                                timestamp: unixTimeStamp,
                            })
                        }
                    />
                    <p className={styles.rushServiceWarningText}>
                        *An additional fee for a rush service will be charged if the service
                        required is within the following 72 hours.
                    </p>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Rooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (residential.room > 0) {
                                    setResidential({ ...residential, room: residential.room - 1 });
                                }
                            }}
                            alt='minus'
                        />
                        <input type='text' value={residential.room} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (residential.room < 15) {
                                    setResidential({ ...residential, room: residential.room + 1 });
                                }
                            }}
                            alt='plus'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Bathrooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (residential.bathroom > 0) {
                                    setResidential({
                                        ...residential,
                                        bathroom: residential.bathroom - 0.5,
                                    });
                                }
                            }}
                            alt='minus'
                        />
                        <input type='text' value={residential.bathroom} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (residential.bathroom < 8) {
                                    setResidential({
                                        ...residential,
                                        bathroom: residential.bathroom + 0.5,
                                    });
                                }
                            }}
                            alt='plus'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Other</h3>
                    <div className={styles.dayWrapper}>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setResidential({ ...residential, living: !residential.living })
                            }
                        >
                            <h5>Living Room</h5>
                            <input type='checkbox' checked={residential.living} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setResidential({ ...residential, dining: !residential.dining })
                            }
                        >
                            <h5>Dining Room</h5>
                            <input type='checkbox' checked={residential.dining} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setResidential({ ...residential, kitchen: !residential.kitchen })
                            }
                        >
                            <h5>Kitchen</h5>
                            <input type='checkbox' checked={residential.kitchen} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setResidential({ ...residential, basement: !residential.basement })
                            }
                        >
                            <h5>Basement</h5>
                            <input type='checkbox' checked={residential.basement} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setResidential({ ...residential, garage: !residential.garage })
                            }
                        >
                            <h5>Garage</h5>
                            <input type='checkbox' checked={residential.garage} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setResidential({ ...residential, pet: !residential.pet })
                            }
                        >
                            <h5>Pet</h5>
                            <input type='checkbox' checked={residential.pet} readOnly />
                        </div>
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setResidential({ ...residential, email: e.target.value.toLowerCase() })
                        }
                        value={residential.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.janitorialButton}>
                        <Button
                            onClick={!submitted ? () => submitResidentialQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResidentialQuote;

const residentialQuoteValidator = (residential) => {
    if (residential.type === '2') {
        alert('Select service type.');
        return false;
    }

    if (
        residential.room === 0 &&
        residential.bathroom === 0 &&
        !residential.kitchen &&
        !residential.basement &&
        !residential.garage &&
        !residential.living &&
        !residential.dining
    ) {
        alert('Must meet minimum service requirements.');
        return false;
    }

    return !!emailValidator(residential.email);
};

const ServiceOptions = () => {
    return (
        <>
            <option value='2' disabled='disabled'>
                Select
            </option>
            <option value='0'>Detailed</option>
            <option value='1'>Post-Construction</option>
        </>
    );
};
