import React, { useState } from 'react';
import Stripe from 'stripe';
import { checkQuoteInvoice, processPayment } from '../../../Services/api.service';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import styles from './payment.module.scss';
import visa from '../../../Assets/Images/Icons/Other/visa.png';
import mastercard from '../../../Assets/Images/Icons/Other/mastercard.png';
import checkmark from '../../../Assets/Images/Icons/Other/check.png';
import errorImg from '../../../Assets/Images/Icons/Other/close.png';
import Button from '../../../Components/Button/Button';
import Loader from '../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const Payment = () => {
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpMonth, setCardExpMonth] = useState('');
    const [cardExpYear, setCardExpYear] = useState('');
    const [cardCvc, setCardCvc] = useState('');
    const [amount, setAmount] = useState('');
    const [invoice, setInvoice] = useState('');
    const [cardType, setCardType] = useState(visa);
    const [amountError, setAmountError] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    // Set your Stripe API key
    const stripe = new Stripe(process.env.REACT_APP_STRIPE_KEY);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateInput()) {
            let fixedAmount = amount.replaceAll(',', '');
            let fixedCardNumber = cardNumber.replaceAll(' ', '');

            if (!fixedAmount.includes('.')) {
                fixedAmount += '.00';
            } else {
                while (fixedAmount.split('.')[1].split('').length < 2) {
                    fixedAmount += '0';
                }
            }

            const confirmBox = window.confirm(
                `Please confirm,\n\nYou are paying an amount of $${fixedAmount} for invoice / quote #${invoice}`,
            );
            if (confirmBox === true) {
                setLoading(true);
                checkQuoteInvoice({
                    number: invoice,
                    amount: fixedAmount,
                })
                    .then(() => {
                        setAmountError('');
                        stripe.tokens
                            .create({
                                card: {
                                    number: fixedCardNumber,
                                    exp_month: cardExpMonth,
                                    exp_year: cardExpYear,
                                    cvc: cardCvc,
                                },
                            })
                            .then((result) => {
                                result.amount = fixedAmount;
                                result.invoice = invoice;
                                processPayment(result)
                                    .then(() => {
                                        setLoading(false);
                                        setSuccess(true);
                                    })
                                    .catch(() => {
                                        setLoading(false);
                                        setError(true);
                                    });
                            })
                            .catch(() => {
                                setLoading(false);
                                setError(true);
                            });
                    })
                    .catch((err) => {
                        setLoading(false);

                        if (err.message === 'Network Error') {
                            window.alert(err.message);
                        }

                        if (err.response.status === 400) {
                            setAmountError(
                                "Invoice/Quote number don't match the amount, please make sure you are entering the correct amount.",
                            );
                        }
                    });
            }
        }
    };

    const handleCardNumberChange = (event) => {
        if (event.target.value.length < 20) {
            const formattedInput = event.target.value
                .replace(/[^\d]/g, '')
                .replace(/\d{1,4}/g, (match) => {
                    return match + ' ';
                })
                .trim();
            setCardNumber(formattedInput);
            if (event.target.value.substring(0, 2) > 50) {
                setCardType(mastercard);
            } else {
                setCardType(visa);
            }
        }
    };

    const handleCardExpMonthChange = (event) => {
        const newMonth = event.target.value.replace(/[^\d]/g, '').slice(0, 2);
        if (/^(\d{0,1}|0?[1-9]|1[0-2])$/.test(newMonth)) {
            setCardExpMonth(newMonth);
        }
    };

    const handleCardExpYearChange = (event) => {
        const year = event.target.value;
        if (/^\d{0,4}$/.test(year)) {
            setCardExpYear(year);
        }
    };

    const handleCvcInput = (event) => {
        const newValue = event.target.value;
        if (/^\d{0,3}$/.test(newValue)) {
            setCardCvc(newValue);
        }
    };

    const handleInvoiceInput = (event) => {
        const newValue = event.target.value;
        if (/^\d{0,5}$/.test(newValue)) {
            setInvoice(newValue);
        }
    };

    const formatAmountInput = (event) => {
        const input = event.target.value.replace(/[^0-9.]/g, '');
        const parts = input.split('.');
        const wholeNumber = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const decimal = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';

        setAmount(wholeNumber + decimal);
    };

    const validateInput = () => {
        const today = new Date();
        const masterCardValidation =
            /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
        const visaCardValidation = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const formattedCard = cardNumber.replaceAll(' ', '');

        let errorText = '';

        if (!(visaCardValidation.test(formattedCard) || masterCardValidation.test(formattedCard))) {
            errorText += 'Please enter a valid card number\n';
        }

        if (cardExpMonth < 1 || cardExpMonth > 12) {
            errorText += 'Enter a valid month\n';
        }

        if (cardExpYear < today.getFullYear()) {
            errorText += 'Year must be in the future\n';
        }

        if (cardExpYear === today.getFullYear() && cardExpMonth < today.getMonth() + 1) {
            errorText += 'Card expiry must be in the future\n';
        }

        if (cardCvc.length < 3) {
            errorText += 'Cvc must be in the correct format\n';
        }

        if (amount < 1) {
            errorText += "Amount can't be lower than $1.00\n";
        }

        if (invoice === '') {
            errorText += 'Enter invoice / quote number';
        }

        if (errorText !== '') {
            window.alert(errorText);
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <Helmet>
                <title>ClearServices - Pay Online</title>
                <meta name='description' content='Pay for your service.' />
                <link rel='canonical' href='/payment' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Header />
                    {error ? (
                        <>
                            <div className={styles.paymentSuccess}>
                                <img src={errorImg} alt='success' />
                                <h2>Payment failed</h2>
                                <h4>
                                    You can either refresh this page and try again, or get in touch
                                    with your card provider.
                                </h4>
                            </div>
                        </>
                    ) : (
                        <>
                            {success ? (
                                <>
                                    <div className={styles.paymentSuccess}>
                                        <img src={checkmark} alt='success' />
                                        <h2>Thank you !</h2>
                                        <h4>Your payment of ${amount} is received.</h4>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.paymentForm}>
                                        <h2 className={styles.centerAlign}>Pay for your service</h2>
                                        <p className={styles.centerAlign}>
                                            You have the option to make a payment using <b>Visa</b>{' '}
                                            or <b>Mastercard</b> below. Alternatively, you can
                                            choose to make a payment via <b>e-transfer</b> to the
                                            email address: contact@clearservices.ca
                                        </p>
                                        <div className={styles.paymentFormCard}>
                                            <h4>Card Number</h4>
                                            <p>Enter the 16-digit card number on the card</p>
                                            <div
                                                className={`${styles.paymentFormCardInput} ${styles.paymentFormCardInputWidth}`}
                                            >
                                                <img src={cardType} alt='card-icon' />
                                                <input
                                                    type='text'
                                                    pattern='\d*'
                                                    value={cardNumber}
                                                    onChange={handleCardNumberChange}
                                                    placeholder='1111 1111 1111 1111'
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.paymentFormRow}>
                                            <div className={styles.paymentFormRowDesc}>
                                                <h4>Expiration Date</h4>
                                                <p>Enter the expiration date of the card</p>
                                            </div>
                                            <div className={styles.paymentFormMonthYear}>
                                                <input
                                                    type='text'
                                                    pattern='\d*'
                                                    maxLength={2}
                                                    value={cardExpMonth}
                                                    onChange={handleCardExpMonthChange}
                                                    placeholder='MM'
                                                />
                                                <input
                                                    type='text'
                                                    pattern='\d*'
                                                    maxLength={4}
                                                    value={cardExpYear}
                                                    onChange={handleCardExpYearChange}
                                                    placeholder='YYYY'
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.paymentFormRow}>
                                            <div className={styles.paymentFormRowDesc}>
                                                <h4>CVC Number</h4>
                                                <p>Card's 3-digit security required</p>
                                            </div>
                                            <input
                                                type='text'
                                                pattern='\d*'
                                                maxLength={3}
                                                value={cardCvc}
                                                onChange={handleCvcInput}
                                                placeholder='123'
                                            />
                                        </div>
                                        <div className={styles.paymentFormRow}>
                                            <div className={styles.paymentFormRowDesc}>
                                                <h4>Amount</h4>
                                                <p>Enter the total amount</p>
                                            </div>
                                            <div
                                                className={`${styles.paymentFormCardInput} ${
                                                    amountError
                                                        ? `${styles.paymentFormErrorBorder}`
                                                        : ''
                                                }`}
                                            >
                                                <p>$</p>
                                                <input
                                                    id='amount-input'
                                                    type='text'
                                                    maxLength={8}
                                                    value={amount}
                                                    onChange={formatAmountInput}
                                                    placeholder='99.99'
                                                />
                                            </div>
                                            <p className={styles.paymentFormError}>
                                                {amountError ? amountError : ''}
                                            </p>
                                        </div>
                                        <div className={styles.paymentFormRow}>
                                            <div className={styles.paymentFormRowDesc}>
                                                <h4>Invoice / Quote</h4>
                                                <p>Enter invoice or quote number</p>
                                            </div>
                                            <div
                                                className={`${styles.paymentFormCardInput} ${
                                                    amountError
                                                        ? `${styles.paymentFormErrorBorder}`
                                                        : ''
                                                }`}
                                            >
                                                <p>#</p>
                                                <input
                                                    id='invoice-input'
                                                    type='text'
                                                    pattern='\d*'
                                                    maxLength={5}
                                                    value={invoice}
                                                    onChange={handleInvoiceInput}
                                                    placeholder='12345'
                                                />
                                            </div>
                                        </div>
                                        <Button onClick={handleSubmit} buttonText='Pay Now' />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <Footer />
                </>
            )}
        </>
    );
};

export default Payment;
