import { useEffect, useRef, useState } from 'react';
import { StudentQuoteState } from '../models';
import { studentQuote } from '../../../../Services/api.service';
import {
    bridgeportHouse,
    getRoomNumber,
    kingStreetTowerOne,
    kingStreetTowerTwo,
    myRez,
    prestonHouse,
} from './BuildingGenerator';
import styles from '../CommercialQuote/commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import DatePicker, { dateToUnixTimeStamp } from '../../../../Components/DatePicker/DatePicker';
import Button from '../../../../Components/Button/Button';

const StudentQuote = () => {
    const [student, setStudent] = useState(StudentQuoteState);
    const [roomNumber, setRoomNumber] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const buildings = {
        kst1: kingStreetTowerOne(),
        kst2: kingStreetTowerTwo(),
        bridgeport: bridgeportHouse(),
        preston: prestonHouse(),
        myrez: myRez(),
    };
    let roomDisplay = [];
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            setStudent(StudentQuoteState);
            firstRender.current = false;
        }
        checkAllSelect();
    }, [student]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRoomNumber(getRoomNumber(student.building, student.unit));
    }, [student.building, student.unit]);

    useEffect(() => {
        fullUnitHandler();
    }, [student.fullUnit]); // eslint-disable-line react-hooks/exhaustive-deps

    const submitStudentQuote = async () => {
        if (studentQuoteValidator(student)) {
            setLoading(true);
            setSubmitted(true);
            await studentQuote(student)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    const fullUnitHandler = () => {
        let newRooms = {};

        if (student.fullUnit) {
            for (let room = 1; room < roomNumber + 1; room++) {
                newRooms[room] = true;
            }
            setStudent({ ...student, commonArea: true, rooms: newRooms });
        } else {
            for (let room = 1; room < roomNumber + 1; room++) {
                newRooms[room] = false;
            }
            setStudent({ ...student, commonArea: false, rooms: newRooms });
        }
    };

    const checkAllSelect = () => {
        if (!student.fullUnit) {
            if (student.commonArea && allUnitsSelected(roomNumber, student.rooms)) {
                setStudent({
                    ...student,
                    fullUnit: true,
                    commonArea: StudentQuoteState.commonArea,
                    rooms: StudentQuoteState.rooms,
                });
            }
        }
    };

    if (student.unit !== 0) {
        if (roomNumber > 0) {
            for (let room = 1; room < roomNumber + 1; room++) {
                roomDisplay.push(
                    <div
                        className={styles.dayRows}
                        key={room}
                        onClick={() =>
                            setStudent({
                                ...student,
                                rooms: { ...student.rooms, [room]: !student.rooms[room] },
                            })
                        }
                    >
                        <h5>#{room}</h5>
                        <input type='checkbox' checked={student.rooms[room]} readOnly />
                    </div>,
                );
            }
        }
    }

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={styles.componentWrapper}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Date</h3>
                    <DatePicker
                        className={styles.datePicker}
                        initialTimestamp={dateToUnixTimeStamp(new Date())}
                        disabledDates={[
                            // yyyy-mm-dd
                            ['2024-08-20', '2024-09-09'],
                        ]}
                        onChange={(unixTimeStamp) =>
                            setStudent({
                                ...student,
                                timestamp: unixTimeStamp,
                            })
                        }
                    />
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Building</h3>
                    <select
                        value={student.building}
                        onChange={(e) =>
                            setStudent({
                                ...student,
                                building: e.target.value,
                                unit: 0,
                                fullUnit: StudentQuoteState.fullUnit,
                                commonArea: StudentQuoteState.commonArea,
                                rooms: StudentQuoteState.rooms,
                            })
                        }
                    >
                        <BuildingOptions />
                    </select>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Unit</h3>
                    <select
                        value={student.unit}
                        onChange={(e) => setStudent({ ...student, unit: e.target.value })}
                    >
                        <option value={0} disabled='disabled'>
                            Select Unit
                        </option>
                        {buildings[student.building]?.map((unit, index) => (
                            <option key={index} value={unit}>
                                {unit}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Options</h3>

                    <div className={styles.dayWrapper}>
                        <div
                            className={styles.dayRows}
                            onClick={() => setStudent({ ...student, fullUnit: !student.fullUnit })}
                        >
                            <h5>Full Unit</h5>
                            <input type='checkbox' checked={student.fullUnit} readOnly />
                        </div>
                        {student.fullUnit ? null : (
                            <>
                                <div
                                    className={styles.dayRows}
                                    onClick={() =>
                                        setStudent({ ...student, commonArea: !student.commonArea })
                                    }
                                >
                                    <h5>Common Area</h5>
                                    <input type='checkbox' checked={student.commonArea} readOnly />
                                </div>
                                {roomDisplay}
                            </>
                        )}
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setStudent({ ...student, email: e.target.value.toLowerCase() })
                        }
                        value={student.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.janitorialButton}>
                        <Button
                            onClick={!submitted ? () => submitStudentQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentQuote;

const studentQuoteValidator = (student) => {
    if (student.building === 'none') {
        alert('Please select a building.');
        return false;
    }

    if (student.unit === 0) {
        alert('Please select a unit.');
        return false;
    }

    if (!student.fullUnit) {
        if (!student.commonArea) {
            const map = new Map(Object.entries(student.rooms));
            if (![...map.values()].includes(true)) {
                alert('Select one of the options below.');
                return false;
            }
        }
    }

    return !!emailValidator(student.email);
};

const allUnitsSelected = (roomNumber, dict) => {
    let selectedUnitCount = 0;

    for (let key in dict) {
        if (dict[key]) {
            selectedUnitCount++;
        }
    }

    return roomNumber === selectedUnitCount;
};

const BuildingOptions = () => {
    return (
        <>
            <option value='none' disabled='disabled'>
                Select Building
            </option>
            <option value='bridgeport'>Bridgeport House</option>
            <option value='kst1'>King Street Towers 1</option>
            <option value='kst2'>King Street Towers 2</option>
            <option value='myrez'>MyRez</option>
            <option value='preston'>Preston House</option>
        </>
    );
};
