import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Navigation from './Components/Navigation/Navigation';
import Home from './Pages/Public/Home/Home';
import PrivacyPolicy from './Pages/Public/Privacy/Privacy';
import Gallery from './Pages/Public/Gallery/Gallery';
import Contact from './Pages/Public/Contact/Contact';
import Services from './Pages/Public/Services/SingleService/Services';
import Login from './Pages/Public/Login/Login';
import Payment from './Pages/Public/Payment/Payment';
import AllQuotes from './Pages/Public/Quote/AllQuotes';
import StudentSpecial from './Pages/Public/StudentSpecial/StudentSpecial';
import TodaysSchedule from './Pages/Private/TodaysSchedule/TodaysSchedule';
import NavigationPublicList from './Data/NavigationPublic.json';
import NavigationPrivateList from './Data/NavigationPrivate.json';
import QuoteGenerator from './Pages/Private/QuoteGenerator/QuoteGenerator';
import InvoiceGenerator from './Pages/Private/Invoices/InvoiceGenerator/InvoiceGenerator';
import Dashboard from './Pages/Private/Dashboard/Dashboard';
import { useRecoilState } from 'recoil';
import { loadingState } from './Recoil/Atoms';
import { syncDb } from './Services/api.service';
import Loader from './Components/Loader/Loader';
import Viewer from './Pages/Private/Viewer/Viewer';
import Editor from './Pages/Private/Editor/Editor';
import ServicesAll from './Pages/Public/Services/AllServices/ServicesAll';
import InvoiceDetails from './Pages/Private/Invoices/InvoiceDetails/InvoiceDetails';
import Invoice from './Pages/Private/Invoices/InvoiceView/Invoice';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const MainLayout = () => (
    <>
        <Navigation menuItems={NavigationPublicList} type={"Public"} />
        <Outlet />
    </>
);

const PrivateLayout = () => (
    <>
        <Navigation menuItems={NavigationPrivateList} type={"Private"} />
        <Outlet />
    </>
);

const App = () => {
    const [loading, setLoading] = useRecoilState(loadingState);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await syncDb();
            setLoading(false);
        })();
    }, [setLoading]);
    return (
        <Router>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <ScrollToTop />
                    <Routes>
                        <Route element={<PrivateLayout />}>
                            <Route path='admin/:title/:id' element={<Editor />} />
                            <Route path='admin/:title' element={<Viewer />} />
                            <Route
                                path='admin/quotes/quote-generator'
                                element={<QuoteGenerator />}
                            />
                            <Route
                                path='admin/invoices/invoice-generator'
                                element={<InvoiceGenerator />}
                            />
                            <Route path='admin/invoices' element={<Invoice />} />
                            <Route path='admin/invoices/:id' element={<InvoiceDetails />} />
                            <Route path='admin/dashboard' element={<Dashboard />} />
                            <Route path='employee/dashboard' element={<Dashboard />} />
                        </Route>
                        <Route element={<MainLayout />}>
                            <Route path='services-all' element={<ServicesAll />} />
                            <Route path='services/:category/:title' element={<Services />} />
                            <Route path='all-quotes' element={<AllQuotes />} />
                            <Route path='privacy-policy' element={<PrivacyPolicy />} />
                            <Route path='payment' element={<Payment />} />
                            <Route path='contact-us' element={<Contact />} />
                            <Route exact path='/' element={<Home />} />
                            <Route path='*' element={<Home />} />
                        </Route>
                        <Route path='login' element={<Login />} />
                        <Route path='gallery' element={<Gallery />} />
                        <Route path='gallery/:id' element={<Gallery />} />
                        <Route path='student-special' element={<StudentSpecial />} />
                        <Route path='schedule/:secret' element={<TodaysSchedule />} />
                    </Routes>
                </>
            )}
        </Router>
    );
};

export default App;
