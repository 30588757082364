import { useEffect, useState } from 'react';
import styles from './login.module.scss';
import ClearServices from '../../../Assets/Images/Logos/ClearServices Logos/ClearServices.png';
import InputField from '../../../Components/InputField/InputField';
import Button from '../../../Components/Button/Button';
import AuthService from '../../../Services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        var response = AuthService.isLogged();
        if (response.auth) {
            if (response.role === 'Admin') {
                navigate('/admin/dashboard');
            } else {
                navigate('/employee/dashboard');
            }
        }
    });

    function handleLogin() {
        AuthService.login(username, password).then(response => {
            if (response.role === 'Admin') {
                navigate('/admin/dashboard');
            } else {
                navigate('/employee/dashboard');
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>ClearServices - Login</title>
                <meta name='description' content='Login to admin panel.' />
                <link rel='canonical' href='/login' />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <a href='/'>
                        <img src={ClearServices} alt='ClearServices' />
                    </a>
                    <div className={styles.card}>
                        <InputField
                            label='Username'
                            path='user.png'
                            placeholder='Enter username'
                            id='username'
                            name='username'
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                            maxLength='25'
                        />
                        <InputField
                            label='Password'
                            path='lock.png'
                            placeholder='Enter password'
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength='25'
                        />
                    </div>
                    <Button buttonText='Login' onClick={handleLogin} />
                    <p className={styles.footerTag}>
                        Copyright © {new Date().getFullYear()} Clear Services Group Inc. All rights
                        reserved.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Login;
