import React, { useState } from 'react';
import styles from './quote-generator.module.scss';
import plus from '../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../Assets/Images/Icons/Other/minus.png';
import { quoteGenerator } from '../../../Services/api.service';
import { useNavigate } from 'react-router-dom';
import { sourceToJson } from '../Invoices/InvoiceGenerator/InvoiceGenerator';
import Loader from '../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const QuoteGenerator = () => {
    const navigate = useNavigate();
    const [client, setClient] = useState('');
    const [scope, setScope] = useState('');
    const [email, setEmail] = useState('');
    const [price, setPrice] = useState('');
    const [pastedExcel, setPastedExcel] = useState('');
    const [quoteType, setQuoteType] = useState('Janitorial');
    const [loading, setLoading] = useState(false);

    const submitButtonHandler = () => {
        let dataSource = [];

        if (pastedExcel.length > 0) {
            dataSource = pastedExcel;
        } else {
            dataSource = convertTableToData();
        }

        const data = {
            client: client,
            scope: scope.replace(/[\r\n]+/gm, ''),
            table: sourceToJson(dataSource),
            email: email,
            quoteType: quoteType,
            price: price,
        };

        if (pdfQuoteValidator(data)) {
            const confirmBox = window.confirm('Are you sure ?');

            if (confirmBox === true) {
                setLoading(true);
                quoteGenerator(data).then((response) => {
                    if (response.status === 200) {
                        navigate(-1);
                    }
                });
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <h2 className='center-align'>Quote Generator</h2>

                    <div className={styles.container}>
                        <h4>Quote Type</h4>
                        <select value={quoteType} onChange={(e) => setQuoteType(e.target.value)}>
                            <QuoteOptions />
                        </select>

                        <h4>Company / Address</h4>
                        <input
                            type='text'
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                            placeholder='To who'
                        />

                        <h4>Scope of Work</h4>
                        <textarea
                            type='text'
                            value={scope}
                            onChange={(e) => setScope(e.target.value)}
                            placeholder='Service scope'
                        />

                        <h4>Line Items</h4>

                        <div className={styles.tableController}>
                            <div className={styles.tableControllerButtons}>
                                <img src={minus} onClick={deleteLastColumn} alt='minus' />
                                <h4>Column</h4>
                                <img src={plus} onClick={addColumn} alt='plus' />
                            </div>

                            <div className={styles.tableControllerButtons}>
                                <img src={minus} onClick={deleteLastRow} alt='minus' />
                                <h4>Row</h4>
                                <img src={plus} onClick={addRow} alt='plus' />
                            </div>
                        </div>

                        <div className={styles.quoteLineItems}>
                            <table id='myTable'>
                                <thead>
                                    <tr id='headerRow'>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                        <th>
                                            <input
                                                id='headerColumn'
                                                type='text'
                                                placeholder='Header'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id='tableBody'>
                                    <tr id='row'>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                    </tr>
                                    <tr id='row'>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                        <td>
                                            <input type='text' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h4>Paste from excel</h4>
                        <textarea
                            type='text'
                            value={pastedExcel}
                            onChange={(e) => setPastedExcel(excelToList(e.target.value))}
                            placeholder='Paste from excel only'
                        />

                        <div className={styles.priceEmailWrapper}>
                            <input
                                type='text'
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                placeholder='Total Price (required)'
                            />

                            <input
                                type='text'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Email (required)'
                            />
                        </div>

                        <button onClick={submitButtonHandler}>Send</button>
                    </div>
                </>
            )}
        </>
    );
};

export default QuoteGenerator;

export const addColumn = () => {
    // create new header
    const headerRow = document.getElementById('headerRow');
    const newColumn = document.createElement('th');
    const newInput = document.createElement('input');
    newColumn.id = 'headerColumn';
    newInput.type = 'text';
    newInput.placeholder = 'Header';
    newColumn.appendChild(newInput);
    headerRow.appendChild(newColumn);

    // create all rows
    const rows = document.querySelectorAll('[id=row]');
    for (let i = 0; i < rows.length; i++) {
        const newRowCell = document.createElement('td');
        const newInput = document.createElement('input');
        newInput.type = 'text';
        newRowCell.appendChild(newInput);
        rows[i].appendChild(newRowCell);
    }
};

export const addRow = () => {
    const headerColumns = document.querySelectorAll('[id=headerColumn]');
    const tableBody = document.getElementById('tableBody');
    const newRow = document.createElement('tr');
    newRow.id = 'row';

    for (let i = 0; i < headerColumns.length; i++) {
        const newRowCell = document.createElement('td');
        const newInput = document.createElement('input');
        newInput.type = 'text';
        newRowCell.appendChild(newInput);
        newRow.appendChild(newRowCell);
    }
    tableBody.appendChild(newRow);
};

export const deleteLastColumn = () => {
    const totalColumns = document.querySelectorAll('[id=headerColumn]');

    if (totalColumns.length > 2) {
        // remove header
        const lastColumn = document.getElementById('headerRow');
        const lastHeader = lastColumn.lastChild;
        lastHeader.parentNode.removeChild(lastHeader);

        // remove each row after header
        const allRows = document.querySelectorAll('[id=row]');
        for (let i = 0; i < allRows.length; i++) {
            const lastRow = allRows[i].lastChild;
            lastRow.parentNode.removeChild(lastRow);
        }
    }
};

export const deleteLastRow = () => {
    const rows = document.querySelectorAll('[id=row]');
    const lastRow = rows[rows.length - 1];

    if (rows.length > 1) {
        lastRow.parentNode.removeChild(lastRow);
    }
};

export const convertTableToData = () => {
    const tableRows = [];
    const header = [];
    const table = document.getElementById('myTable');
    const thElements = table.getElementsByTagName('th');
    const rows = table.getElementsByTagName('tr');

    for (let i = 0; i < thElements.length; i++) {
        header.push(thElements[i].querySelector('input').value);
    }

    tableRows.push(header);

    for (let q = 1; q < rows.length; q++) {
        const cells = rows[q].getElementsByTagName('td');
        const rowCells = [];
        for (let j = 0; j < cells.length; j++) {
            rowCells.push(cells[j].querySelector('input').value);
        }
        tableRows.push(rowCells);
    }
    return tableRows;
};

export const excelToList = (text) => {
    const rowList = [];
    // Split the text into rows
    const rows = text.trim().split('\n');

    rows.forEach((element) => {
        rowList.push(element.split('\t'));
    });

    return rowList;
};

export const QuoteOptions = () => {
    return (
        <>
            <option value='Janitorial'>Janitorial</option>
            <option value='Contracting'>Contracting</option>
        </>
    );
};

export const pdfQuoteValidator = (data) => {
    let errorText = '';

    if (data.quoteType === 2) {
        errorText += 'Must select a quote type\n';
    }

    if (data.client === '' || data.scope === '' || data.price === '' || data.email === '') {
        errorText += "Fields can't be blank\n";
    }

    if (errorText) {
        window.alert(errorText);
        return false;
    } else {
        return true;
    }
};
