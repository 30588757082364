import Footer from '../../../Components/Footer/Footer';
import clearcleaning from '../../../Assets/Images/Logos/ClearServices Logos/ClearCleaning.png';
import clearcontracting from '../../../Assets/Images/Logos/ClearServices Logos/ClearContracting.png';
import { useEffect, useState } from 'react';
import CommercialQuote from './CommercialQuote/CommercialQuote';
import ResidentialQuote from './ResidentialQuote/ResidentialQuote';
import CarpetQuote from './CarpetQuote/CarpetQuote';
import PaintQuote from './PaintQuote/PaintQuote';
import { accordionState } from './models';
import StudentQuote from './StudentHouseQuote/StudentQuote';
import FloorQuote from './FloorQuote/FloorQuote';
import styles from './all-quotes.module.scss';
import { Helmet } from 'react-helmet-async';
import GroutQuote from './GroutQuote/GroutQuote';

const AllQuotes = () => {
    const [accordion, setAccordion] = useState(accordionState);

    useEffect(() => {
        let currentPage = window.location.href.split('#')[1];

        if (currentPage === 'contracting') {
            document.getElementById('contracting').scrollIntoView();
        }
    }, []);

    const accordionHandler = (selected) => {
        let currentAccordion = '';

        for (const key in accordion) {
            if (accordion[key] === true) {
                currentAccordion = key;
            }
        }

        if (currentAccordion !== '') {
            setAccordion({ [currentAccordion]: false });
            document.getElementById(currentAccordion).style.backgroundColor = '#ffffff';
            document.getElementById(currentAccordion).style.color = 'gray';
        }

        if (currentAccordion !== selected) {
            setTimeout(() => {
                setAccordion({ [selected]: true });
            }, 1);

            if (selected === 'paint' || selected === 'floor') {
                document.getElementById(selected).style.backgroundColor = '#e79124';
            } else {
                document.getElementById(selected).style.backgroundColor = '#a5ce8a';
            }
            document.getElementById(selected).style.color = '#ffffff';
            setTimeout(() => {
                window.scrollBy(
                    0,
                    document.getElementById(selected).getBoundingClientRect().top - 70,
                );
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>ClearServices - Generate Quotes</title>
                <meta name='description' content='Generate instant quotes and book your services online.' />
                <link rel='canonical' href='/all-quotes' />
            </Helmet>
            <div className={styles.companyLogo}>
                <img src={clearcleaning} alt='logo' />
            </div>

            <div
                id='commercial'
                className={styles.quoteSection}
                onClick={() => accordionHandler('commercial')}
            >
                <h2>Commercial Quote</h2>
            </div>

            {accordion.commercial ? <CommercialQuote /> : null}

            <div
                id='student'
                className={styles.quoteSection}
                onClick={() => accordionHandler('student')}
            >
                <h2 id='Student'>Student Quote</h2>
            </div>

            {accordion.student ? <StudentQuote /> : null}

            <div
                id='residential'
                className={styles.quoteSection}
                onClick={() => accordionHandler('residential')}
            >
                <h2>Residential Quote</h2>
            </div>

            {accordion.residential ? <ResidentialQuote /> : null}

            <div
                id='carpet'
                className={styles.quoteSection}
                onClick={() => accordionHandler('carpet')}
            >
                <h2 id='Carpet'>Carpet Quote</h2>
            </div>

            {accordion.carpet ? <CarpetQuote /> : null}

            <div
                id='grout'
                className={styles.quoteSection}
                onClick={() => accordionHandler('grout')}
            >
                <h2 id='Grout'>Grout Quote</h2>
            </div>

            {accordion.grout ? <GroutQuote /> : null}

            <div id='contracting' className={styles.companyLogo}>
                <img src={clearcontracting} alt='logo' />
            </div>

            <div
                id='paint'
                className={`${styles.quoteSection} ${styles.contractingSection}`}
                onClick={() => accordionHandler('paint')}
            >
                <h2 id='Paint'>Paint Quote</h2>
            </div>

            {accordion.paint ? <PaintQuote /> : null}

            <div
                id='floor'
                className={`${styles.quoteSection} ${styles.contractingSection}`}
                onClick={() => accordionHandler('floor')}
            >
                <h2 id='Floor'>Floor Quote</h2>
            </div>

            {accordion.floor ? <FloorQuote /> : null}

            <Footer />
        </>
    );
};

export default AllQuotes;
